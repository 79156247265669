jQuery(function ($) {

    // console.log('here');
    //
    // var iframe = $("nivo-lightbox-item", window.parent.document).html();
    // console.log('iframe: ' + iframe);
    // iframe.load(function () {
    //     $("body").height(iframe.height());
    //     console.log('iframe.height(): ' + iframe.height())
    // });

    $( document ).ready(function() {
        $(document).on("keypress", "input", function (e) {
            var code = e.keyCode || e.which;
            if (code === 13) {
                e.preventDefault();
                return false;
            }
        });
    });

});